

.slide-rectangle {
    width: 51px;
    height: 4px;
    border-radius: 100px;
    background-color: #C8CBD0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
}
.slide-rectangle.active {
    background: #000000;
}