body {
  background-color: #1E1E1E;
  color: #FFF;
}
.form-input, input, select, textarea {
  color: #1E1E1E;
}

.preview-iframe {
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  width: 360px;
  /* height: 180px; */
}
.preview-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.output-placeholder {
  width: 360px;
  height: 203px;
  background-size: 0px;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.output-placeholder .output-status {
  background-color: darkslategrey;
  border-radius: 1rem;
  /* font-weight: bold; */
  color: white;
  padding: 0.5rem;
}
.output-placeholder:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.18;
  background-image: inherit;
  background-repeat: no-repeat;
  background-size: contain;
}
.next-event-item {
  border: 1px solid rgba(173,255,47, 0.5);
}
.event-status-active,.event-status-live,.event-status-going_live,.event-status-scheduled,.event-status-ending {
  border: 1px solid rgba(173,255,47, 0.3);
  background-color: rgba(173,255,47, 0.2);

}
.event-status-ended {
  opacity: 0.6;
}