.container {
  /* min-height: 100vh; */
  /* max-height: 100vh; */
}

/* TODO: unnecessary. this is the image's actual size, it's already set */
.heroSlider {
  width: 320px;
  height: 350px;
  /* background-image: */
}
